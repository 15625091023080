<template>
<div>
 <transition name="fade" mode="out-in">
   <div v-if="!USER || !loaded" :key="auth">
     <Auth @first="auth = false" v-if="auth"/>
     <Start v-else @auth="auth = true" :loading="!loaded"/>
   </div>
   <router-view v-else/>
 </transition>
</div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import Auth from "@/pages/Auth/Auth";
import Start from "@/pages/Start";

export default {
  name: 'App',
  components: {Start, Auth},
  data: function () {
    return {
      auth: false,
      loaded: false,
    }
  },
  async created() {
    await this.FETCH_INIT();
    this.loaded = true;
  },
  methods: {
    ...mapActions(['FETCH_INIT']),
  },
  computed: {
    ...mapGetters(['USER']),
  }
}
</script>
