<template>
  <div style="display: flex; justify-content: center; align-items: center; height: 66px">
    <svg id="L9" enable-background="new 0 0 0 0" style="width: 50px; height: 50px;" version="1.1"
         viewBox="0 0 100 100" x="0px" xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
    <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          fill="#fff">
      <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="1s"
          from="0 50 50"
          repeatCount="indefinite"
          to="360 50 50"
          type="rotate"/>
  </path>
</svg>
  </div>
</template>

<script>
export default {
  name: "StartLoader"
}
</script>