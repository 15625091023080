<template>
<div class="app-input">
  <textarea @input="setV" :value="v"  v-if="t === 'textarea'" :placeholder="placeholder" :maxlength="max"></textarea>
  <input @input="setV" :value="v" v-else :type="t" :placeholder="placeholder" :maxlength="max">
   <label>{{label}}</label>
  <div class="characters" v-if="max">{{l}}/{{max}}</div>
</div>
</template>

<script>
export default {
  name: "AppInput",
  props:["placeholder","label", "type", "modelValue", "max"],
  data: function (){
    return{
      v: '',
    }
  },
  watch: {
    modelValue(v){
     this.v = v;
    }
  },
  methods: {
    setV(e){
      this.v = e.target.value;
      this.$emit('update:modelValue', e.target.value);
    }
  },
  mounted() {
    this.v = this.modelValue || '';
  },
  computed:{
    l(){
      return this.v.length;
    },
    t(){
      return this.type || 'text';
    },
  }

}
</script>