<template>
  <div class="auth-page">
    <transition name="fade" mode="out-in">
      <component
          @auth="state = 0"
          :is="comp"
          @recovery="state = 1"
          @first="$emit('first')"
      />
    </transition>
  </div>
</template>

<script>
import Recovery from "@/pages/Auth/Recovery/Index";
import Login from "@/pages/Auth/Login/Index";

export default {
  name: "Auth",
  components: {Recovery, Login},
  computed: {
    comp(){
      return [
          Login,
          Recovery,
      ][this.state];
    }
  },
  data: function () {
    return {
      state: 0,
    }
  }
}
</script>