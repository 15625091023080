import Vuex from 'vuex'
import Api from "@/Api";

export default  new Vuex.Store({
    state: {
        COUNTRIES: null,
        USER: null,
    },
    mutations: {
        SET_COUNTRIES(state, data){
            state.COUNTRIES = data;
        },
        SET_USER(state, data){
          state.USER = data;
        },
    },
    actions: {
        async FETCH_INIT({commit}){
            commit('SET_COUNTRIES', await Api.countries.get());
        }
    },
    getters: {
        COUNTRIES: state => state.COUNTRIES,
        USER: state => state.USER,
    }
})