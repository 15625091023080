<template>
  <general :back="() => {$emit('back')}">
    <template v-slot:heading>
      Введите полученный код <br>активации
    </template>
    <template v-slot:label>
      На ваш номер телефона {{beautifyPhone}}<br> отправлен код активации профиля.
    </template>
    <div class="code_input" :class="{error}">
      <input @focus="onInputFocus" v-model="code"  @keypress="isNumber($event)" type="text" :disabled="checking">
      <div :class="{has:code.length===0}">
        <transition name="fade">
          <div>
            <div v-if="symbol1">{{ symbol1 }}</div>
          </div>
        </transition>
      </div>
      <div :class="{has:code.length===1}">
        <transition name="fade">
          <div>
            <div v-if="symbol2">{{ symbol2 }}</div>
          </div>

        </transition>
      </div>
      <div :class="{has:code.length===2}">
        <transition name="fade">
          <div>
            <div v-if="symbol3">{{ symbol3 }}</div>
          </div>
        </transition>
      </div>
      <div :class="{has:code.length===3}">
        <transition name="fade">
          <div>
            <div v-if="symbol4">{{ symbol4 }}</div>
          </div>
        </transition>
      </div>
      <div :class="{has:code.length===4}">
        <transition name="fade">
          <div>
            <div v-if="symbol5">{{ symbol5 }}</div>
          </div>
        </transition>
      </div>
      <div :class="{has:code.length===5}">
        <transition name="fade">
          <div>
            <div v-if="symbol6">{{ symbol6 }}</div>
          </div>
        </transition>
      </div>
    </div>
    <div class="wait-message" v-if="checking || error">
      {{checking ? 'Подождите, отправляем запрос на сервер' : 'Проверьте ещё раз правильность ввода'}}
    </div>
    <template v-slot:footer>
      <AppBtn :disabled="nextAfter !== null || checking" @click="sendCode" class="btn btn__block" arrow :loading="checking">
        Выслать код повторно{{nextAfter ? ' через '+nextAfter : ''}}
      </AppBtn>
    </template>
  </general>
</template>

<script>
import AppBtn from "@/components/Form/AppBtn";
import General from "@/layouts/general";
import Api from "@/Api";

export default {
  name: 'Step2',
  props: ['phone'],
  components: {
    AppBtn, General
  },
  data: function () {
    return {
      checking: false,
      error: false,
      code: '',
      allow: null,
      interval: null,
      date: null,
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  watch: {
    code(v) {
      if (v.length === 6) {
        this.checkCode();
      }
    }
  },
  computed: {
    beautifyPhone(){
      let right = this.phone.substring(this.phone.length - 10).match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
      right.shift();

      const code  = this.phone.substring(0, this.phone.length - 10);

      return ['+'+code, ...right].join(' ');
    },
    symbol1() {
      return this.code.charAt(0);
    },
    symbol2() {
      return this.code.charAt(1);
    },
    symbol3() {
      return this.code.charAt(2);
    },
    symbol4() {
      return this.code.charAt(3);
    },
    symbol5() {
      return this.code.charAt(4);
    },
    symbol6() {
      return this.code.charAt(5);
    },
    nextAfter(){
      const sec = Math.floor((this.allow.getTime() - this.date.getTime()) / 1000);

      if(sec < 0){
        return null;
      }

      const m = Math.floor(sec / 60);
      let   s = sec - 60 * m;

      if(s < 10){
        s = '0'+s;
      }

      return m+':'+s;
    },
  },
  beforeMount() {
    this.date = new Date();
    this.interval = setInterval(() => {
      this.date = new Date();
    }, 1000);
    this.sendCode();
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    onInputFocus(){
      if(this.error){
        this.error = false;
        this.code = '';
      }
    },
    sendCode(){
      this.allow = new Date((new Date).getTime() + 10 * 1000);
    },
    async checkCode(){
      this.checking = true;
      const val = await Api.auth.check(this.code);
      this.checking = false;

      if(val){
        this.$router.push({name: 'information'});
        this.$store.commit('SET_USER', val);
        return;
      }

      this.error = true;
    }
  }
}
</script>