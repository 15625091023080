import {createRouter, createWebHistory} from 'vue-router';
import { h, resolveComponent } from 'vue';

let routes;
routes = [
    {
        name: 'events',
        path: '/events',
        component: {
            render() {
                return h(resolveComponent('router-view'))
            }
        },
        children: [
            {
                path: '',
                name: 'events.index',
                component: () => import('../pages/Events/Events.vue')
            },
            {
                path: ':id(\\d+)',
                name: 'events.item',
                component: () => import('../pages/Events/Event.vue')
            },
            {
                path: 'add',
                        component: () => import('../pages/Events/AddEvent/AddEvent.vue'),
                children:[
                    {
                        path: '',
                        name: 'events.add',
                        component: () => import('../pages/Events/AddEvent/AddEvent1.vue'),
                    },
                    {
                        path: '2',
                        name: 'events.add.2',
                        component: () => import('../pages/Events/AddEvent/AddEvent2.vue'),
                    },
                    {
                        path: '3',
                        name: 'events.add.3',
                        component: () => import('../pages/Events/AddEvent/AddEvent3.vue'),
                    },
                    {
                        path: '4',
                        name: 'events.add.4',
                        component: () => import('../pages/Events/AddEvent/AddEvent2.vue'),
                    },

                ]
            },


        ],
    },
     {
        name: 'information',
        path: '/information',
        component: () => import('../pages/information.vue')
    }
    , {
        name: 'change',
        path: '/change',
        component: () => import('../pages/Auth/Recovery/change.vue')
    }
];

export default createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0,behavior: 'smooth', }
    },
})