<template>
  <div class="modal-wrapper">
    <transition name="fade" appear mode="out-in">
      <div class="modal-wrapper__background" v-if="show" @click="show=false"></div>
    </transition>
    <transition name="slide">
      <div class="modal-wrapper__content-wrapper"  v-if="show">
        <div class="modal_content" @click="show=false"></div>
        <div class="modal_content_place">
                 <div class="modal-label">{{label}}</div>
                    <div class="scroll_wrap">
            <slot/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SlideModal",
  props: ['modelValue','label'],
  computed: {
    show: {
      get(){
        return this.modelValue;
      },
      set(r){
        this.$emit('update:modelValue', r);
      }
    }
  }
}
</script>

<style scoped>

</style>