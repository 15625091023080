<template>
     <general :back="back">
      <template v-slot:heading>
        Изменить номер телефона
      </template>
      <template v-slot:label>
        Новый номер телефона будет использоваться для входа в аккаунт
      </template>
      <div class="black-label">Телефон</div>
      <app_phone_input v-model="phone"/>
       <template v-slot:footer>
         <AppBtn @click="$router.push({name:'wait_code'})" class="btn btn__block">
           Сохранить
         </AppBtn>
       </template>
     </general>
</template>

<script>
import AppBtn from "@/components/Form/AppBtn";
import General from "@/layouts/general";
import App_phone_input from "@/components/Form/app_phone_input";

export default {
  name: 'change',
  data: function (){
    return{
      phone:''
    }
  },
  components: {
    App_phone_input,
    General,
    AppBtn,
      },
  methods: {
    back(){
      this.$emit('back');
    }
  }
}
</script>

<style scoped>

</style>