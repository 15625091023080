<template>
  <div>
    <transition name="fade" mode="out-in">
      <component :phone="phone" @phone="goNext" @back="back" @recovery="$emit('recovery')" :is="comp"/>
    </transition>
  </div>
</template>

<script>
import Step1 from "@/pages/Auth/Login/Step1";
import Step2 from "@/pages/Auth/Login/Step2";

export default {
  name: "Index",
  components: {Step2},
  computed: {
    comp(){
      return [
          Step1,
          Step2,
      ][this.step];
    }
  },
  methods: {
    goNext(phone){
      this.phone = phone;
      this.step++;
    },
    back(){
      if(!this.step){
        return this.$emit('first');
      }
      this.step--;
    }
  },
  data: function () {
    return {
      step: 0,
      phone: null,
    }
  }
}
</script>