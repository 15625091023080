<template>
  <general :back="() => {$emit('back')}">
    <template v-slot:heading>
      Укажите свой<br> номер телефона
    </template>
    <template v-slot:label>
      Мы вышлем вам смс-код<br> для подтверждения личности
    </template>
    <AppPhoneInput v-model="phone"/>
    <template v-slot:footer>
      <AppBtn :disabled="!phone" @click="$emit('phone', phone)" class="btn btn__block" arrow>
        Отправить код
      </AppBtn>
      <AppBtn @click="$emit('recovery')" class="btn__white btn__block offset-top">Восстановление доступа</AppBtn>
    </template>
  </general>
</template>

<script>
import AppBtn from "@/components/Form/AppBtn";
import General from "@/layouts/general";
import AppPhoneInput from "@/components/Form/app_phone_input";

export default {
  name: 'Step1',
  data: function (){
    return{
      phone: null,
    }
  },
  components: {
    AppPhoneInput,
    General,
    AppBtn
  }
}
</script>