<template>
<div class="phone_input_wrapper">
  <div class="country_choice">
<img class="flag" src="../../assets/images/image130.svg">
     <div @click="show=true" class="country_name">{{country.name}}</div>
    <img class="expand" src="../../assets/icons/arrowri.svg">
      </div>
    <div class="phone_number">
      <div class="phone_code">+{{country.dial_code}} </div>
      <input v-model="val" v-maska data-maska="### ### ## ##" type="text" placeholder="000 000 00 00">
          </div>
  <SlideModal v-model="show" label="Выбор страны"><div class="country_item" v-for="(country, key) in COUNTRIES" :key="key" @click="setCountry(country)">
    <div class="flag">
    </div>
    <div class="country_code_wrapper">
      <div class="country_name">
        {{country.name}}
      </div>
      <div class="country_code">
        {{country.dial_code}}
      </div>
    </div>
  </div></SlideModal>
</div>
</template>

<script>
import { vMaska } from "maska"
import {mapGetters} from 'vuex';
import SlideModal from "@/components/Form/SlideModal";

export default {
  components: {SlideModal},
  directives: {
    maska: vMaska
  },
  props: {
    modelValue: {
      type: String,
      default: null,
      nullable: true,
    }
  },
  name: "app_phone_input",
  created() {
    this.setBase();
  },
  watch: {
    val(){
      this.updateValue();
    },
    country(){
      this.updateValue();
    }
  },
  methods: {
    setBase(){
      const data = this.split();

      if(!data || !data[0].length){
        this.country = this.COUNTRIES[0];
        return;
      }

      this.val = data[1];

      const country = this.COUNTRIES.find(e => e.dial_code === data[0]);

      this.country = country ? country : this.COUNTRIES[0];
      this.prev = this.buildVal();
    },
    buildVal(){
      const right = this.val.replace(/ /g,'');

      if(right.length !== 10){
        return null;
      }

      return this.country.dial_code+right;
    },
    updateValue(){
      const val = this.buildVal();

      if(val === this.prev){
        return;
      }

      this.$emit('update:modelValue', val);
      this.prev = val;
    },
    split(){
      if(!this.modelValue){
        return null;
      }

      const right = this.modelValue.substring(this.modelValue.length - 10);

      return [this.modelValue.substring(0, this.modelValue.length - 10), right];
    },
    setCountry(country){
      this.country = country;
      this.show = false;
    }
  },
  data: function () {
    return {
      show: false,
      country: null,
      countries: null,
      val: '',
      prev: null,
    }
  },
  computed:{
    ...mapGetters(['COUNTRIES']),
    refVal: {
      get(){
        return this.modelValue
            ? ''
            : ''
      },
      set(v){
        this.$emit
      }
    },
    /*
  val:{
    get(){
      return this.modelValue;
    },
    set(v){
     this.$emit('update:modelValue',v)
    }
  }
     */
  }
}

</script>

<style scoped>

</style>
