<template>
  <div>
    <transition name="fade" mode="out-in">
      <component :is="comp" @next="step++" @back="back"/>
    </transition>
  </div>
</template>

<script>
import step1 from "@/pages/Auth/Recovery/step1";
import change from "@/pages/Auth/Recovery/change";

export default {
  name: 'index',
  components: {step1, change},
  computed: {
    comp(){
      return this.step === 0 ? step1 : change;
    },
  },
  methods: {
    back(){
      if(this.step){
        this.step--;
        return;
      }

      this.$emit('auth');
    }
  },
  data: function (){
    return{
     step: 0,
    }
  },
  }
</script>