<template>
<button class="btn">
  <transition name="fade" mode="out-in">
    <svg class="rotate-animation" v-if="loading" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0177 10.3189C11.411 10.9535 10.6774 11.4532 9.86473 11.7854C9.05206 12.1175 8.17852 12.2748 7.30103 12.2468C6.42353 12.2189 5.56177 12.0064 4.77189 11.6232C3.982 11.2399 3.28171 10.6946 2.71665 10.0227C2.15158 9.35077 1.73441 8.56734 1.49232 7.72344C1.25023 6.87954 1.18864 5.9941 1.31158 5.12482C1.43452 4.25553 1.73922 3.42189 2.20583 2.67822C2.67244 1.93454 3.29049 1.29752 4.01972 0.808645" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <template v-else>
      <div style="display: flex;width: 100%;align-items: center" v-if="arrow">
        <div style="flex-grow: 1;text-align: center"><slot/></div>
        <img src="../../assets/icons/Arrow-R.svg">
      </div>
      <slot v-else/>
    </template>
  </transition>

</button>
</template>

<script>
export default {
  name: "AppBtn",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
  }
}
</script>