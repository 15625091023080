<template>
<div class="general-layout" :class="{'general-layout__with-menu': menu}">
 <div class="general-layout__content">
   <header>
     <div class="header_item">
    <button @click="back ? back() : $router.go(-1)" class="button_page_back"><img src="../assets/icons/Arrow-L.svg"></button>
     <slot name="edit"/>
     </div>
  </header>
  <h1>
   <slot name="heading"/>
  </h1>
  <div class="general-layout__label">
    <slot name="label"/>
  </div>
  <slot/>
 </div>
<footer v-if="hasFooterSlot">
  <slot name="footer"/>
</footer>
  <div v-if="menu" class="navi_menu">
    <div><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 7C16.5 4.19108 16.5 2.78661 15.8259 1.77772C15.534 1.34096 15.159 0.965956 14.7223 0.674122C13.7134 0 12.3089 0 9.5 0H7.5C4.69108 0 3.28661 0 2.27772 0.674122C1.84096 0.965956 1.46596 1.34096 1.17412 1.77772C0.5 2.78661 0.5 4.19108 0.5 7C0.5 9.80892 0.5 11.2134 1.17412 12.2223C1.46596 12.659 1.84096 13.034 2.27772 13.3259C3.15907 13.9148 4.3423 13.9892 6.5 13.9986V14L7.60557 16.2111C7.9741 16.9482 9.0259 16.9482 9.39443 16.2111L10.5 14V13.9986C12.6577 13.9892 13.8409 13.9148 14.7223 13.3259C15.159 13.034 15.534 12.659 15.8259 12.2223C16.5 11.2134 16.5 9.80892 16.5 7ZM5.5 4C4.94772 4 4.5 4.44772 4.5 5C4.5 5.55228 4.94772 6 5.5 6H11.5C12.0523 6 12.5 5.55228 12.5 5C12.5 4.44772 12.0523 4 11.5 4H5.5ZM5.5 8C4.94772 8 4.5 8.44772 4.5 9C4.5 9.55228 4.94772 10 5.5 10H8.5C9.05228 10 9.5 9.55228 9.5 9C9.5 8.44772 9.05228 8 8.5 8H5.5Z" fill="#A5A6A9"/>
    </svg>
    </div>
    <div><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00248 6.97519C7.28492 4.15083 9.66156 2 12.5 2V2C15.3384 2 17.7151 4.15083 17.9975 6.97519L18.2841 9.84133C18.3016 10.0156 18.3103 10.1028 18.3207 10.1886C18.4649 11.3717 18.8717 12.5077 19.5113 13.5135C19.5576 13.5865 19.6062 13.6593 19.7034 13.8051L20.5645 15.0968C21.3508 16.2763 21.744 16.866 21.5715 17.3412C21.5388 17.4311 21.4935 17.5158 21.4368 17.5928C21.1371 18 20.4283 18 19.0108 18H5.98923C4.57168 18 3.86291 18 3.56318 17.5928C3.50651 17.5158 3.46117 17.4311 3.42854 17.3412C3.25601 16.866 3.64916 16.2763 4.43548 15.0968L5.29661 13.8051C5.39378 13.6593 5.44236 13.5865 5.48873 13.5135C6.12832 12.5077 6.53508 11.3717 6.67927 10.1886C6.68972 10.1028 6.69844 10.0156 6.71587 9.84133L7.00248 6.97519Z" fill="#A5A6A9"/>
      <path d="M10.5681 20.6294C10.6821 20.7357 10.9332 20.8297 11.2825 20.8967C11.6318 20.9637 12.0597 21 12.5 21C12.9403 21 13.3682 20.9637 13.7175 20.8967C14.0668 20.8297 14.3179 20.7357 14.4319 20.6294" stroke="#A5A6A9" stroke-width="2" stroke-linecap="round"/>
    </svg>
    </div>
    <router-link
        custom
        v-slot="{ navigate, isActive}"
        :to="{name: 'events.index'}">
      <div  @click="navigate" :class="{active: isActive}">
        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6.36107C4 5.24948 2.52611 4.95485 2.13508 5.99493C1.1892 8.51083 0.5 10.7615 0.5 11.9783C0.5 15.8563 3.63401 19 7.5 19C11.366 19 14.5 15.8563 14.5 11.9783C14.5 10.671 13.7045 8.17038 12.6498 5.42956C11.2836 1.87912 10.6005 0.103904 9.75726 0.00828682C9.48744 -0.0223091 9.19308 0.0326993 8.95234 0.158706C8.2 0.552499 8.2 2.48869 8.2 6.36107C8.2 7.52446 7.2598 8.46758 6.1 8.46758C4.9402 8.46758 4 7.52446 4 6.36107Z" fill="#A5A6A9"/>
        </svg>
      </div>
    </router-link>
    <div>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 9C2.5 7.11438 2.5 6.17157 3.08579 5.58579C3.67157 5 4.61438 5 6.5 5H18.5C20.3856 5 21.3284 5 21.9142 5.58579C22.5 6.17157 22.5 7.11438 22.5 9C22.5 9.4714 22.5 9.70711 22.3536 9.85355C22.2071 10 21.9714 10 21.5 10H3.5C3.0286 10 2.79289 10 2.64645 9.85355C2.5 9.70711 2.5 9.4714 2.5 9Z" fill="#A5A6A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.08579 21.4142C2.5 20.8284 2.5 19.8856 2.5 18V13C2.5 12.5286 2.5 12.2929 2.64645 12.1464C2.79289 12 3.0286 12 3.5 12H21.5C21.9714 12 22.2071 12 22.3536 12.1464C22.5 12.2929 22.5 12.5286 22.5 13V18C22.5 19.8856 22.5 20.8284 21.9142 21.4142C21.3284 22 20.3856 22 18.5 22H6.5C4.61438 22 3.67157 22 3.08579 21.4142ZM8.5 16C7.94772 16 7.5 16.4477 7.5 17C7.5 17.5523 7.94772 18 8.5 18H16.5C17.0523 18 17.5 17.5523 17.5 17C17.5 16.4477 17.0523 16 16.5 16H8.5Z" fill="#A5A6A9"/>
        <path d="M7.5 3L7.5 6" stroke="#A5A6A9" stroke-width="2" stroke-linecap="round"/>
        <path d="M17.5 3L17.5 6" stroke="#A5A6A9" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
    <router-link
        custom
        v-slot="{ navigate, isActive}"
        :to="{name: 'information'}">
      <div  @click="navigate" :class="{active: isActive}">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 10C2.5 6.22876 2.5 4.34315 3.67157 3.17157C4.84315 2 6.72876 2 10.5 2H14.5C18.2712 2 20.1569 2 21.3284 3.17157C22.5 4.34315 22.5 6.22876 22.5 10V14C22.5 17.7712 22.5 19.6569 21.3284 20.8284C20.1569 22 18.2712 22 14.5 22H10.5C6.72876 22 4.84315 22 3.67157 20.8284C2.5 19.6569 2.5 17.7712 2.5 14V10ZM8.23867 16.4465C9.46118 15.5085 10.9591 15 12.5 15C14.0409 15 15.5388 15.5085 16.7613 16.4465C17.9838 17.3846 18.8627 18.6998 19.2615 20.1883C19.4044 20.7217 19.0878 21.2701 18.5544 21.413C18.0209 21.556 17.4726 21.2394 17.3296 20.7059C17.0448 19.6427 16.417 18.7033 15.5438 18.0332C14.6706 17.3632 13.6007 17 12.5 17C11.3993 17 10.3294 17.3632 9.45619 18.0332C8.58297 18.7033 7.95525 19.6427 7.67037 20.7059C7.52743 21.2394 6.97909 21.556 6.44563 21.413C5.91216 21.2701 5.59558 20.7217 5.73852 20.1883C6.13734 18.6998 7.01616 17.3846 8.23867 16.4465ZM10.5 9C10.5 7.89543 11.3954 7 12.5 7C13.6046 7 14.5 7.89543 14.5 9C14.5 10.1046 13.6046 11 12.5 11C11.3954 11 10.5 10.1046 10.5 9ZM12.5 5C10.2909 5 8.5 6.79086 8.5 9C8.5 11.2091 10.2909 13 12.5 13C14.7091 13 16.5 11.2091 16.5 9C16.5 6.79086 14.7091 5 12.5 5Z" fill="#A5A6A9"/>
          <rect x="3" y="2.5" width="19" height="19" rx="3.5" stroke="#A5A6A9"/>
        </svg>
      </div>
    </router-link>
  </div>
</div>
</template>

<script>
import Edit from "@/components/Form/edit";
export default {
  name: "general",
  components: {Edit},
  props: {
    back: {

    },
    menu: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    }
  }
}
</script>