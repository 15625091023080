<template>
  <general :back="back">
    <template v-slot:heading>
      Восстановление доступа<br>
      к аккаунту Come On
    </template>
    <template v-slot:label>
      Мы вышлем вам смс-код<br> для подтверждения личности
    </template>
    <div class="black-label">Телефон</div>
    <app_phone_input v-model="phone"/>
    <div class="black-label">Эл. Почта</div>
    <div class="helper">На вашу почту придет код для восстановления доступа к аккаунту</div>
    <AppInput v-model="email" label="Электронный адрес" placeholder="Укажите ваш адрес эл.почты" type="text" style="margin-top: 12px"/>
    <template v-slot:footer>
      <AppBtn
          :disabled="!available"
          @click="showed = true"
          class="btn btn__block"
          arrow
      >
        Отправить код
      </AppBtn>
    </template>
    <div class="modal-wrapper">
      <transition name="fade">
        <div class="modal-wrapper__content-wrapper"  v-if="showed">
          <div class="modal-wrapper__background" v-if="showed" @click="showed=false"></div>
          <div class="alert">
            <div class="content">На вашу почту name@gmail.com отправлена ссылка для востановлеия доступа к аккаунту.</div>
            <div class="controls">
              <button  @click="$emit('next')">Понятно</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </general>
</template>

<script>
import AppBtn from "@/components/Form/AppBtn";
import General from "@/layouts/general";
import App_phone_input from "@/components/Form/app_phone_input";
import AppInput from "@/components/Form/AppInput";

export default {
  name: 'step1',
  data: function (){
    return{
      phone: null,
      showed:  false,
      email: '',
    }
  },
  computed: {
    available(){
      return this.phone && this.email.toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
  },
  components: {
    App_phone_input,
    General,
    AppBtn,
    AppInput
  },
  methods: {
    back(){
      this.$emit('back');
    }
  }
}
</script>