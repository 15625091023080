<template>
    <div class="edit_btn">Редактировать <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.30635 14.8969C6.93558 15.2652 6.33444 15.2652 5.96367 14.8969L3.50209 12.4519C3.13132 12.0836 3.13132 11.4865 3.50209 11.1183L13.9079 0.78258C14.9584 -0.26086 16.6616 -0.26086 17.7121 0.78258C18.7626 1.82602 18.7626 3.51777 17.7121 4.56121L7.30635 14.8969Z" fill="url(#paint0_linear_248_5163)"/>
    <path d="M1.3455 17.9627C0.84355 18.1393 0.3598 17.6588 0.537564 17.1602L1.77877 13.6791C1.93413 13.2433 2.49389 13.1175 2.82297 13.4444L5.08651 15.6927C5.41559 16.0195 5.28894 16.5755 4.85025 16.7298L1.3455 17.9627Z" fill="url(#paint1_linear_248_5163)"/>
    <defs>
      <linearGradient id="paint0_linear_248_5163" x1="19.8846" y1="-9.78261" x2="3.73839" y2="28.5594" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DE4585"/>
        <stop offset="0.5" stop-color="#FD5960"/>
        <stop offset="1" stop-color="#FF7134"/>
        <stop offset="1" stop-color="#FE8742"/>
      </linearGradient>
      <linearGradient id="paint1_linear_248_5163" x1="19.8846" y1="-9.78261" x2="3.73839" y2="28.5594" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DE4585"/>
        <stop offset="0.5" stop-color="#FD5960"/>
        <stop offset="1" stop-color="#FF7134"/>
        <stop offset="1" stop-color="#FE8742"/>
      </linearGradient>
    </defs>
  </svg>
  </div>
  </template>

<script>
export default {
  name: "edit"
}
</script>

<style scoped>

</style>