<template>
  <div class="start-page">
    <div>
      <div class="start-page__top">
        <img src="../assets/images/startmap.png">
        <div class="heading">
          Создавай, находи и присоединяйся к событиям в мире
        </div>
        <div class="label">
          Теория наивного и сентиментального искусства, в том числе, дает канон биографии.
        </div>
      </div>
      <div class="start-page__bottom">
        <transition mode="out-in" name="fade">
          <AppBtn v-if="!loading" class="btn__white btn__block" @click="$emit('auth')">Продолжить</AppBtn>
          <StartLoader v-else/>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>


import AppBtn from "@/components/Form/AppBtn";
import StartLoader from "@/components/StartLoader";

export default {
  props: ['loading'],
  name: 'Start',
  components: {
    StartLoader,
    AppBtn
  }
}
</script>